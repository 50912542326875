import { Dayjs } from 'dayjs';
import { Withdrawal } from '../../components/Payments/Withdrawals/types';
import { Paginated, PaginationQuery } from '../../utils/pageControllers.utils';
import { InfluencerData, NonInfluencerData } from '../Discovery/store';
import { TransactionStatus, PaymentType } from '../Programmes/store';

export interface PaymentsState {
  orderIdentifierQueries: OrderIdentifierQueries;
  transactionStatuses: string[] | null;
  isFetchingTransactionStatuses: boolean;
  fetchTransactionStatusesError: string | null;
  paginatedPayments: PaginatedPayments | null;
  paginatedScheduledPayments: PaginatedScheduledPayments | null;
  paginatedCommissions: PaginatedCommissions | null;
  standardPaymentFilters: PaymentQueries;
  scheduledPaymentFilters: ScheduledPaymentsQueries;
  CommissionFilters: CommissionQueries;

  orderIdentifiers: string[] | null;

  paginatedInfluencers: Paginated<InfluencerData> | null;
  isFetchingInfluencers: boolean | null;
  influencersError: string | null;

  paginatedNonInfluencers: Paginated<NonInfluencerData> | null;
  isFetchingNonInfluencers: boolean | null;
  nonInfluencersError: string | null;

  isFetchingPayments: boolean;
  fetchPaymentsError: string | null;
  selectedPayments: Payment[];
  currentCommission: CommissionDetails | null;

  requestStatuses: string[] | null;
  scheduleStatuses: string[] | null;

  isBulkApproving: boolean;
  isBulkRejecting: boolean;
  bulkApproveError: string | null;
  bulkRejectError: string | null;

  isScheduleApproving: boolean;
  isScheduleRejecting: boolean;
  isScheduleCancelling: boolean;
  isSchedulePausing: boolean;
  scheduleApproveError: string | null;
  scheduleRejectError: string | null;
  scheduleCancelError: string | null;
  schedulePauseError: string | null;

  isFetchingCSV: boolean;
  paymentsCSV: string | null;
  fetchCSVError: string | null;

  isPostingRecurringPayment: boolean;
  postingRecurringPaymentError: string | null;

  isFetchingScheduledPayments: boolean;
  scheduledPaymentsError: string | null;

  isFetchingRequestStatuses: boolean;
  requestStatusesError: string | null;

  isFetchingScheduleStatuses: boolean;
  scheduleStatusesError: string | null;

  isFetchingCommissionsList: boolean;
  commissionsListError: string | null;

  isFetchingCommission: boolean;
  currentCommissionError: string | null;

  isPostingCommissions: boolean;
  postingCommissionsError: string | null;

  isDeletingSchedule: boolean;
  isDeletingBonus: boolean;
  deletingScheduleError: string | null;
  deletingBonusError: string | null;

  isEditingBonus: boolean;
  isEditingSchedule: boolean;
  editBonusError: string | null;
  editScheduleError: string | null;

  isFetchingBonusIssuer: boolean;
  fetchingBonusIssuerError: string | null;

  isFetchingInvoices: boolean;
  isFetchingInvoicesError: boolean;
  paginatedInvoices: PaginatedInvoices | null;

  // TODO: migrate all errors and loading states
  isFetching: {
    withdrawals: boolean;
    selfbillId: boolean;
    orderIdentifiers: boolean;
  };

  error: {
    withdrawals: string | null;
    selfbillId: string | null;
    orderIdentifiers: string | null;
  };

  paginatedWithdrawals: PaginatedWithdrawals | null;

  withdrawalToSelfbillMappings: WithdrawalToSelfbillMapping[];
}

export interface WithdrawalToSelfbillMapping {
  withdrawalId: string;
  selfbillId: string;
}

export interface WithdrawalToSelfbillMappingPaginated {
  selfbillMappings: WithdrawalToSelfbillMapping[];
  pagination: Pagination;
}

export const defaultStandardPaymentFilters: StandardPaymentFilters = {
  date_from: null,
  date_to: null,
  status: null,
  programme_group: null,
  programme: null,
  influencer: null,
  payment_type: null,
};

export const defaultScheduledPaymentFilters: ScheduledPaymentsFilters = {
  start_date_from: null,
  start_date_to: null,
  end_date_from: null,
  end_date_to: null,
  request_status: null,
  schedule_status: null,
  programme_id: null,
  programme_group_id: null,
  influencer_id: null,
  created_by_id: null,
};

export const defaultCommissionFilters: CommissionFilters = {
  start_date: null,
  end_date: null,
  status: null,
  programme_id: null,
  influencer_id: null,
  tracking_source: null,
  order_id: null,
};

export const defaultStandardPaymentQueries: PaymentQueries = {
  page: 0,
  limit: 15,
  date_from: null,
  date_to: null,
  status: null,
  programme_group: null,
  programme: null,
  influencer: null,
  payment_type: null,
};

export const defaultScheduledPaymentQueries: ScheduledPaymentsQueries = {
  page: 0,
  limit: 15,
  start_date_from: null,
  start_date_to: null,
  end_date_from: null,
  end_date_to: null,
  request_status: null,
  schedule_status: null,
  programme_id: null,
  programme_group_id: null,
  influencer_id: null,
  created_by_id: null,
};

export const defaultCommissionQueries: CommissionQueries = {
  page: 0,
  limit: 15,
  start_date: null,
  end_date: null,
  status: null,
  programme_id: null,
  influencer_id: null,
  tracking_source: null,
  order_id: null,
};

export const defaultOrderIdentifierQueries: OrderIdentifierQueries = {
  page: 0,
  limit: 15,
  order_id: null,
};

export const defaultPaymentsState: PaymentsState = {
  transactionStatuses: null,
  isFetchingTransactionStatuses: false,
  fetchTransactionStatusesError: null,
  isFetchingNonInfluencers: false,
  nonInfluencersError: null,
  paginatedNonInfluencers: null,
  paginatedPayments: null,
  paginatedScheduledPayments: null,
  paginatedCommissions: null,
  standardPaymentFilters: defaultStandardPaymentQueries,
  scheduledPaymentFilters: defaultScheduledPaymentQueries,
  CommissionFilters: defaultCommissionQueries,
  orderIdentifierQueries: defaultOrderIdentifierQueries,
  orderIdentifiers: null,

  paginatedInfluencers: null,
  isFetchingInfluencers: false,
  influencersError: null,

  isFetchingPayments: false,
  fetchPaymentsError: null,
  selectedPayments: [],
  currentCommission: null,

  requestStatuses: null,
  scheduleStatuses: null,

  isBulkApproving: false,
  isBulkRejecting: false,
  bulkApproveError: null,
  bulkRejectError: null,
  isFetchingCSV: false,
  paymentsCSV: null,
  fetchCSVError: null,

  isPostingRecurringPayment: false,
  postingRecurringPaymentError: null,

  isFetchingScheduledPayments: false,
  scheduledPaymentsError: null,

  isFetchingRequestStatuses: false,
  requestStatusesError: null,

  isFetchingScheduleStatuses: false,
  scheduleStatusesError: null,

  isFetchingCommissionsList: false,
  commissionsListError: null,

  isFetchingCommission: false,
  currentCommissionError: null,

  isPostingCommissions: false,
  postingCommissionsError: null,

  isScheduleApproving: false,
  isScheduleRejecting: false,
  isScheduleCancelling: false,
  isSchedulePausing: false,
  scheduleApproveError: null,
  scheduleRejectError: null,
  scheduleCancelError: null,
  schedulePauseError: null,

  isDeletingSchedule: false,
  isDeletingBonus: false,
  deletingScheduleError: null,
  deletingBonusError: null,

  isEditingBonus: false,
  isEditingSchedule: false,
  editBonusError: null,
  editScheduleError: null,

  isFetchingBonusIssuer: false,
  fetchingBonusIssuerError: null,

  isFetchingInvoices: false,
  isFetchingInvoicesError: false,
  paginatedInvoices: null,

  isFetching: {
    withdrawals: false,
    selfbillId: false,
    orderIdentifiers: false,
  },
  error: {
    withdrawals: null,
    selfbillId: null,
    orderIdentifiers: null,
  },

  withdrawalToSelfbillMappings: [],
  paginatedWithdrawals: null,
};

export interface StandardPaymentFilters {
  date_from: string | null;
  date_to: string | null;
  status: TransactionStatus | null;
  programme_group: string | null;
  programme: string | null;
  influencer: string | null;
  payment_type: PaymentType | null;
  created_by: string | null;
}

export interface PaymentQueries extends StandardPaymentFilters {
  limit: number;
  page: number;
}

export enum RequestStatus {
  PENDING_APPROVAL = 'pending_approval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ScheduleStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export enum CommissionStatus {
  PENDING = 'pending',
  REQUIRES_ATTENTION = 'requires_attention',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CLEARED = 'cleared',
}

export enum CommissionAutoApproveStatus {
  REQUIRES_ATTENTION = 'requires_attention',
  PENDING = 'pending',
  CHECKED = 'checked',
}

export enum CommissionDesiredStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum CommissionStatusFilter {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface ScheduledPaymentsFilters {
  start_date_from: string | null;
  start_date_to: string | null;
  end_date_from: string | null;
  end_date_to: string | null;
  request_status: RequestStatus | null;
  schedule_status: ScheduleStatus | null;
  programme_id: string | null;
  programme_group_id: string | null;
  influencer_id: string | null;
  created_by_id: string | null;
}

export interface ScheduledPaymentsQueries extends ScheduledPaymentsFilters {
  limit: number;
  page: number;
}

export interface CommissionFilters {
  start_date: string | null;
  end_date: string | null;
  status: string | null;
  programme_id: string | null;
  influencer_id: string | null;
  tracking_source: string | null;
  order_id: string | null;
}

export interface CommissionFiltersAPI {
  startDate: string | null;
  endDate: string | null;
  commissionStatus: string | null;
  programmeId: string | null;
  influencerId: string | null;
  orderIdentifier: string | null;
}

export interface CommissionQueries extends CommissionFilters {
  limit: number;
  page: number;
}

export interface OrderIdentifierQueries {
  limit: number;
  page: number;
  order_id: string | null;
}

export type ScheduledPaymentsByPageNumber = Map<number, ScheduledPayment[]>;

export interface PaginatedScheduledPayments {
  scheduledPayments: ScheduledPaymentsByPageNumber;
  pagination: Pagination;
}

export enum VatPaymentStatus {
  'estimated',
  'permanent',
}

export interface VatPayment {
  amount: number;
  status: VatPaymentStatus;
}

export type PaymentsByPageNumber = Map<number, Payment[]>;
export interface Payment {
  influencer_id: string;
  influencer_name: string;
  influencer_avatar: string | null;
  programme_id: string;
  programme_name: string;
  programme_group_id: string | null;
  issuer_id: string;
  created_at: string;
  payment_type: string;
  amount: string;
  status: string;
  currency: string;
  transaction_id: string;
  reference?: string;
  additional_comments?: string;
  vat: VatPayment | null;
}

export interface PaymentIds {
  paymentIds: string[];
}

export interface PaymentIdsBody {
  payment_ids: string[];
}
export interface Pagination {
  total_count: number;
  limit: number;
  page: number;
}
export interface PaginatedPayments {
  payments: PaymentsByPageNumber;
  pagination: Pagination;
}
export type CommissionsByPageNumber = Map<number, Commission[]>;
export interface Commission {
  id: string;
  programmeId: string;
  programmeName: string;
  programmeCurrency: string;
  influencerId: string;
  influencerName: string;
  influencerAvatar: string | null;
  orderDate: string;
  orderValue: number;
  orderCommission: number;
  orderIdentifier: string;
  status: string;
  saleSource: string;
}

export interface CommissionAPI {
  id: string;
  programme_id: string;
  programme_name: string;
  programme_currency: string;
  influencer_id: string;
  influencer_name: string;
  influencer_avatar: string | null;
  order_date: string;
  order_value: number;
  order_commission: number;
  order_identifier: string;
  status: string;
  sale_source: string;
}

export interface CommissionAutoApproveErrorAPI {
  code: string | null;
  message: string;
}

export interface PaginatedCommissions {
  commissions: CommissionsByPageNumber;
  pagination: Pagination;
}

export interface CommissionDetailsAPI {
  id: string;
  programme_id: string;
  influencer_id: string;
  programme_name: string;
  programme_currency: string;
  influencer_name: string;
  influencer_avatar: string | null;
  status: string;
  customer_type: string;
  created_at: string;
  order_identifier: string;
  assisted_sale: boolean;
  campaign_id: string | null;
  items: CommissionItemAPI[];
  auto_approval_errors: CommissionAutoApproveErrorAPI[];
  sale_source: string;
}

export interface CommissionDetails {
  id: string;
  programmeId: string;
  influencerId: string;
  programmeName: string;
  programmeCurrency: string;
  influencerName: string;
  influencerAvatar: string | null;
  status: string;
  customerType: string;
  createdAt: string;
  orderIdentifier: string;
  assistedSale: boolean;
  campaignId: string | null;
  items: CommissionItem[];
  autoApprovalErrors: CommissionAutoApproveErrorAPI[];
  saleSource: string;
}

export interface CommissionItem {
  commissionItemId: string;
  sku: string;
  productName: string;
  category: string;
  subcategory: string;
  value: number;
  commission: number;
  status: CommissionStatus;
  desiredStatus: CommissionDesiredStatus | null;
  autoApproveStatus: CommissionAutoApproveStatus;
  voucherCode: string | null;
  rejectionReason: string | null;
}

export interface CommissionItemAPI {
  commission_item_id: string;
  sku: string;
  product_name: string;
  category: string;
  subcategory: string;
  value: number;
  commission: number;
  status: CommissionStatus;
  desired_status: CommissionDesiredStatus | null;
  auto_approve_status: CommissionAutoApproveStatus;
  voucher_code: string | null;
  rejection_reason: string | null;
}

export enum PaymentsBulkActions {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  DELETE = 'Delete',
}

export interface RejectBulkApiModel {
  transactions: string[];
  reject_reason: string;
}

export interface RecurringPaymentBody {
  influencer_id: string;
  programme_id: string;
  payment_frequency: string;
  payment_amount: number;
  start_date: string;
  end_date: string;
  payment_reference: string;
  additional_comments: string | null;
  campaign_id: string | null;
}

export interface EditBonusAPIPayload {
  payment_ref: string | null;
  transaction_commission: number;
  additional_comments: string | null;
}

export interface EditRecurringAPIPayload {
  payment_amount: number;
  start_date: string;
  end_date: string;
  payment_reference: string;
  additional_comments: string | null;
}

export interface ScheduledPayment {
  id: string;
  influencerId: string;
  influencerName: string;
  influencerAvatar: string | null;
  programmeId: string;
  programmeGroupId: string | null;
  programmeName: string;
  paymentFrequency: string;
  paymentAmount: number;
  startDate: string;
  endDate: string;
  paymentReference: string;
  additionalComments: string | null;
  createdAt: string;
  createdByKeycloakId: string;
  approvedAt: string | null;
  approvedByKeycloakId: string | null;
  cancelledAt: string | null;
  cancelledByKeycloakId: string | null;
  rejectedAt: string | null;
  rejectedByKeycloakId: string | null;
  lastUpdated: string;
  pauses: Pause[];
  requestStatus: string;
  scheduleStatus: string;
  currency: string;
  vat: VatPayment | null;
}

export interface Pause {
  pausedAt: string;
  pausedByKeycloakId: string;
  resumedAt: string | null;
  resumedByKeycloakId: string | null;
}

export enum PaymentFrequency {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface IssuerDetails {
  first_name: string;
  last_name: string;
}

export interface PaymentApprovalAPIModel {
  transaction_id: string;
  approved_by_marketer_id: string | null;
  approved_date: string | null;
  approved_by_first_name: string | null;
  approved_by_last_name: string | null;
}

export interface VatSyncOverviewAPI {
  payment_id: string;
  payment_amount: number;
  vat_amount: number;
}

export interface VatSyncOverview {
  paymentId: string;
  paymentAmount: number;
  vatAmount: number;
}
export interface PaymentApproval {
  transactionId: string;
  approvedById: string | null;
  approvedDate: string | null;
  approvedByFirstName: string | null;
  approvedByLastName: string | null;
}
export interface PaymentClearanceAPIModel {
  transaction_id: string;
  cleared_date: string;
}

export interface WithdrawalToSelfbillMappingAPIModel {
  withdrawal_id: string;
  selfbill_id: string;
}

export interface WithdrawalToSelfbillMappingPaginatedAPIModel {
  selfbill_mappings: WithdrawalToSelfbillMappingAPIModel[];
  pagination: Pagination;
}

export interface PaymentClearance {
  transactionId: string;
  clearedDate: string;
}

export interface CommissionUpdate {
  approve: string[] | null;
  reject: string[] | null;
}

export type InvoiceStatus = 'pending' | 'paid';
export interface InvoiceAPI {
  id: string;
  partnerize_invoice_id: string;
  programme_id: string;
  campaign_id: string;
  invoice_created_at: string;
  status: InvoiceStatus;
  amount: number;
  currency: string;
}

export interface PaginatedInvoicesAPI {
  pagination: Pagination;
  invoices: InvoiceAPI[];
}

export interface PaginatedOrderIdentifiersAPI {
  pagination: Pagination;
  order_ids: string[];
}
export interface Invoice {
  id: string;
  partnerizeInvoiceId: string;
  programmeId: string;
  campaignId: string;
  invoiceCreatedAt: Dayjs;
  status: InvoiceStatus;
  amount: number;
  currency: string;
}

export type PaginatedInvoices = Paginated<Invoice>;

export interface InvoiceQueries extends PaginationQuery {
  programme_id: string | null;
  start_date: string | null;
  end_date: string | null;
  status: InvoiceStatus | null;
}

export interface WithdrawalAPI {
  transaction_id: string;
  influencer_id: string;
  influencer_name: string;
  influencer_avatar: string | null;
  amount: number;
  currency: string;
  created_at: string;
  selfbill_id: string;
}

export interface PaginatedWithdrawalsAPI {
  pagination: Pagination;
  withdrawals: WithdrawalAPI[];
}

export type PaginatedWithdrawals = Paginated<Withdrawal>;

export interface WithdrawalsQueries {
  limit: number;
  page: number;
  date_from: string | null;
  date_to: string | null;
  influencer_id: string | null;
  selfbill_id: string | null;
}

export * from './reducer';
export * from './actions';
