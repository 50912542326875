import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';
import { GlobalFontFamily } from '../../../styling/css/fontfamily';

export const SimpleTable = styled.table`
  padding: ${props => theme(props).SIZE.XSMALL};
  height: 100%;
  width: 100%;

  border-spacing: 0 0.5rem;
  border-collapse: separate;

  & td,
  th {
    text-align: left;
    padding: ${props => theme(props).SIZE.XSMALL};
  }

  th {
    font-family: ${props => theme(props).TYPOGRAPHY.FONT_FAMILY_BOLD};
    font-weight: ${props => theme(props).TYPOGRAPHY.FONT_WEIGHT_BOLD};
    font-weight: bold;
  }
`;

export const Table = styled.table<{
  isGrey?: boolean;
  hideBorders?: boolean;
  limitHeaderSize?: boolean;
  useBlockDisplay?: boolean;
}>`
  width: 100%;
  text-align: left;
  ${GlobalFontFamily}
  font-size: 1rem;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  ${props =>
    props.isGrey &&
    css`
      border-collapse: separate;
      border-spacing: 0 ${px2rem(8)};
      border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
    `};

  thead > tr {
    height: 3rem;
  }
  tbody > tr > td:first-child {
    border-top-left-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
    border-bottom-left-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  }
  tbody > tr > td:last-child {
    border-top-right-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
    border-bottom-right-radius: ${props =>
      theme(props).EFFECTS.RADIUS_STANDARD};
  }
  tbody > tr {
    padding: 20px 0;
    border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};

    ${props =>
      props.isGrey
        ? css`
            background-color: ${theme(props).COLOUR.BACKGROUND_LIGHTEST};
            margin-bottom: : ${theme(props).SIZE.XSMALL};
          `
        : !props.hideBorders &&
          `border-bottom: 1px solid ${theme(props).COLOUR.BACKGROUND_LIGHT};`};
  }

  tbody > tr > td,
  thead > tr > th {
    padding: 0.5rem;
    position: relative;
  }
  thead > tr > th:first-child {
    padding-left: ${props => theme(props).SIZE.AVERAGE};
  }
  tbody > tr > td:first-child {
    padding-left: ${props => theme(props).SIZE.AVERAGE};
  }
  tbody > tr > td:last-child {
    padding-right: ${props => theme(props).SIZE.AVERAGE};
  }

  th {
    font-family: ${props => theme(props).TYPOGRAPHY.FONT_FAMILY_BOLD};
    font-weight: ${props => theme(props).TYPOGRAPHY.FONT_WEIGHT_BOLD};
    ${props => props.limitHeaderSize && `width: ${px2rem(200)}`};
  }

  a {
    color: ${props => theme(props).COLOUR.PRIMARY};
    cursor: pointer;
    text-decoration: none;
  }

  ${props => {
    if (props.useBlockDisplay)
      return css`
        table,
        thead,
        tbody,
        tr,
        th,
        td {
          display: block;
          text-align: left;
        }

        tr {
          padding: ${theme(props).SIZE.XSMALL} 0 !important;
        }
        td {
          padding: 0 !important;
        }
      `;
  }}
`;

export const Chevron = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
  color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
`;

export const TableHeader = styled.thead``;
export const TableBody = styled.tbody``;
export const TableRow = styled.tr`
  vertical-align: middle;
`;
export const ClickableTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const TableRowWithChevron = styled(ClickableTableRow)`
  vertical-align: middle;
  &:hover ${Chevron} {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }
`;
export const TableCell = styled.td`
  vertical-align: middle;
`;
export const TableHead = styled.th`
  font-family: ${props => theme(props).TYPOGRAPHY.FONT_FAMILY_BOLD};
  font-weight: ${props => theme(props).TYPOGRAPHY.FONT_WEIGHT_BOLD};
  text-align: center;
`;

export const StartTableHead = styled.th`
  font-family: ${props => theme(props).TYPOGRAPHY.FONT_FAMILY_BOLD};
  font-weight: ${props => theme(props).TYPOGRAPHY.FONT_WEIGHT_BOLD};
`;

export const CentredTableCell = styled.td`
  text-align: center;
  vertical-align: middle;
`;
